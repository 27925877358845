import About from './components/about/About';
import GetStarted from './components/getStarted/GetStarted';
import Services from './components/services/Services';
import Contact from './components/contact/Contact';

function App() {
  return (
    <div>
      <GetStarted />
      <Services />
      <About />
      <Contact />
    </div>
  );
}

export default App;
