import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header id='header'>
      <div className='container d-flex align-items-center'>
        <h1 className='logo mr-auto d-flex align-items-center'>
          <img src='assets/img/logo.png' alt='Formrally.com' className='img-fluid mr-1' />
          <Link to='/'>
            Form<span style={{ color: '#47b2e4' }}>Rally</span>
          </Link>
        </h1>

        <nav className='nav-menu d-none d-lg-block'></nav>
      </div>
    </header>
  );
};

export default Header;
