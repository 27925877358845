import React, { useState, Fragment } from 'react';
import axios from 'axios';
import Spinner from '../../layout/Spinner';

const initialState = {
  token: process.env.REACT_APP_TOKEN,
  type: 'From Monday.Formrally.com',
  name: '',
  email: '',
  subject: '',
  message: '',
};

const Contact = () => {
  const [formData, setFormData] = useState(initialState);
  const [alert, setAlert] = useState(undefined);
  const [loadSend, setLoadSend] = useState(false);

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
  return (
    <section id='contact' className='contact'>
      <div className='container' data-aos='fade-up'>
        <div className='section-title'>
          <h2>Contact</h2>
          <p>
            If the solution you’re looking for is not on our portfolio yet, feel free to contact us. Our PMO would be
            ‎more than happy to further assist and direct you to the right team. Don’t forget to leave an email ‎address
            or any other method you would like us to contact you back.‎
          </p>
        </div>

        <div className='row'>
          <div className='col-lg-5 d-flex align-items-stretch'>
            <div className='info'>
              <div className='address'>
                <i className='icofont-google-map'></i>
                <h4>Location:</h4>
                <p>Yizhak Rabin 51, Givatayim</p>
              </div>

              <div className='email'>
                <i className='icofont-envelope'></i>
                <h4>Email:</h4>
                <p>yogev@formrally.com</p>
              </div>

              <iframe
                title='myFrame'
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381.094421282539!2d34.80607771526309!3d32.066694827169144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4ba8bea91139%3A0xa7ba08173d223a8!2sDerech%20Yitshak%20Rabin%2051%2C%20Giv&#39;atayim!5e0!3m2!1sen!2sil!4v1609218901637!5m2!1sen!2sil'
                frameBorder='0'
                style={{ border: '0', width: '100%', height: '290px' }}
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className='col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch'>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                setLoadSend(true);
                const result = await axios.post('https://formrallymailer.herokuapp.com/incomingLead', formData);
                if (result.data.error) {
                  setAlert('ERROR');
                  setLoadSend(false);
                  return null;
                }
                setAlert('SUCCESS');
                setFormData(initialState);
                setLoadSend(false);
                return console.log(result.data);
              }}
              className='php-email-form'
            >
              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <label htmlFor='name'>Your Name</label>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    id='name'
                    data-rule='minlen:4'
                    data-msg='Please enter at least 4 chars'
                    value={formData.name}
                    defaultValue={formData.name}
                    placeholder='...'
                    onChange={(e) => onChange(e)}
                  />
                  <div className='validate'></div>
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='name'>Your Email</label>
                  <input
                    type='email'
                    className='form-control'
                    name='email'
                    id='email'
                    data-rule='email'
                    data-msg='Please enter a valid email'
                    value={formData.email}
                    defaultValue={formData.email}
                    placeholder='example@email.com'
                    onChange={(e) => onChange(e)}
                  />
                  <div className='validate'></div>
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='name'>Subject</label>
                <input
                  type='text'
                  className='form-control'
                  name='subject'
                  id='subject'
                  data-rule='minlen:4'
                  data-msg='Please enter at least 8 chars of subject'
                  value={formData.subject}
                  defaultValue={formData.subject}
                  placeholder='How can we help?'
                  onChange={(e) => onChange(e)}
                />
                <div className='validate'></div>
              </div>
              <div className='form-group'>
                <label htmlFor='name'>Message</label>
                <textarea
                  className='form-control'
                  name='message'
                  rows='10'
                  data-rule='required'
                  data-msg='Please write something for us'
                  value={formData.message}
                  defaultValue={formData.message}
                  placeholder='Tell us more...'
                  onChange={(e) => onChange(e)}
                ></textarea>
                <div className='validate'></div>
              </div>
              <div className='mb-3'>
                <div className='loading'>Loading</div>
                <div className='error-message'></div>
                <div className='sent-message'>Your message has been sent. Thank you!</div>
              </div>
              <div className='text-center'>
                {!loadSend ? (
                  <Fragment>
                    {formData.email !== '' && formData.subject !== '' ? (
                      <button type='submit'>Send Message</button>
                    ) : null}
                  </Fragment>
                ) : (
                  <Spinner />
                )}
                {alert === 'ERROR' ? (
                  <p className='text-danger'>Something went wrong: please send by mail to yogev@formrally.com</p>
                ) : null}
                {alert === 'SUCCESS' ? (
                  <p className='text-success'>Thank you, Message was send. We will contact you soon.</p>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
