import React from 'react';

const Services = () => {
  return (
    <section id="services" className="services section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Services</h2>
          <p>
            Here's a list of Formrally's apps and services for Monday.com. This
            is an ongoing project, the apps will be updated and will contain
            additional features in the future. We hope you will be able to find
            a proper solution to your Monday.com workflow using our services.
          </p>
        </div>

        <div className="row">
          <div
            className="col-xl-3 col-md-6 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-plug"></i>
              </div>
              <h4>
                <a href="https://monday.formrally.com/mondaycsv/">CSV Link</a>
              </h4>
              <p>
                This app will convert your board to a static URL containing a
                CSV file you can use in your Google Sheets with the
                =importData("URL") function.
              </p>
            </div>
          </div>

          <div
            className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-file"></i>
              </div>
              <h4>
                <a href="https://auth.monday.com/oauth2/authorize?client_id=38455110fc3fbf76f79ad2e6e01d681c&response_type=install">
                  Monday Reports
                </a>
              </h4>
              <p>
                Export your boards (single or multiple) to spread sheet files
                such as CSV or XLSX. The app works as a view you attach to any
                board on your Monday account.
              </p>
            </div>
          </div>
          <div
            className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-layer"></i>
              </div>
              <h4>
                <a href="https://auth.monday.com/oauth2/authorize?client_id=ff9ffd63d69455448bfa36137259ba3f&response_type=install">
                  Monday vLookApp
                </a>
              </h4>
              <p>
                This app works like any other look up function, only this time
                it's for Monday boards. Click to install and add it as an
                integration to any board. You can read more about it{' '}
                <a href="https://community.monday.com/t/vlookapp-a-free-vlookup-function-for-monday/21287?u=yoggie">
                  here.
                </a>{' '}
                ‎
              </p>
            </div>
          </div>
          <div
            className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bxs-note"></i>
              </div>
              <h4>
                <a href="https://auth.monday.com/oauth2/authorize?client_id=cdaaf5633f9483e7a7ec772b89391b76&response_type=install">
                  Private Notes
                </a>
              </h4>
              <p>
                This view will allow you to control who can see and edit the
                board’s notes. By adding a new view you will become the owner,
                and you will be able to manage the approved users list. Learn
                more in this{' '}
                <a href="https://community.monday.com/t/feature-request-private-notes-hide-from-guests/2298/17?u=yoggie">
                  discussion
                </a>{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
