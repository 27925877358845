import React, { Fragment } from 'react';

const About = () => {
  return (
    <Fragment>
      <section id='about' className='about'>
        <div className='container' data-aos='fade-up'>
          <div className='section-title'>
            <h2>About Us</h2>
          </div>

          <div className='row content'>
            <div className='col-lg-6'>
              <p>
                We're a devoted team of software engineers and full stack ‎developers located in Israel.‎ We use a wide
                variety of software architectures and ‎stacks to match our client’s demands and existing assets.‎
              </p>
              <p>Our integrations usually require us to have one or more of these capabilities:‎</p>
              <ul>
                <li>
                  <i className='ri-check-double-line'></i> Integrating one or more client’s endpoints to either perform
                  a task or be able to view and edit data. ‎
                </li>
                <li>
                  <i className='ri-check-double-line'></i> Find a solution on an existing infrastructure, to enable API
                  integrations.‎
                </li>
                <li>
                  <i className='ri-check-double-line'></i> Develop custom apps that replace old solutions which aren’t
                  capable of integrating with other ‎endpoints.‎
                </li>
              </ul>
            </div>
            <div className='col-lg-6 pt-4 pt-lg-0'>
              <p>
                Almost every business nowadays needs some sort of IT solution to its data management and ‎information
                systems. Formrally's developers not only offer such solutions, it also provides the client a tailor made
                ‎development of apps, cloud microservices and custom software.‎
              </p>
              <p>
                We invite you to look through our existing services. You would get a better understanding of the type
                ‎of solutions we are providing. Feel free to contact us if you have any further questions.‎
              </p>
              <a href='https://formrally.com' className='btn-learn-more'>
                Learn More About Formrally.com
              </a>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default About;
